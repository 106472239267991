import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ActionToolType from "./ActionToolType";
import ActionToolEnum from "./ActionToolEnum";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import SntLink from "../../../components/ReactBootstrap/SntLink";
import SntFilterSettingIcon from "../../../components/Icons/SntFilterSettingIcon";

const FilterTool = ({
  list = [],
  onClick = (id) => {
    console.log("click id: " + id);
  },
}) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user, shallowEqual);
  const { features } = loginInfo;
  const [filterAction, setFilterAction] = useState([]);

  useEffect(() => {
    let filters = list.filter(
      (option) => option.type === ActionToolType.filter
    );
    // check condition for search_save filter
    if (!features.search_save) {
      filters = filters.filter(
        (action) =>
          action.id !== ActionToolEnum.save_filter &&
          action.id !== ActionToolEnum.load_filter
      );
    }
    setFilterAction(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick]);

  const handleClick = (id) => {
    onClick && onClick(id);
  };

  const filterActionList = useMemo(() => {
    return filterAction.map((action, idx) => {
      return (
        <Dropdown.Item
          key={action.id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            console.log("click");
            handleClick(action.id);
          }}
        >
          {action.title}
        </Dropdown.Item>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAction]);

  return (
    <>
      {list.length > 0 && (
        <Dropdown
          className={`hide-arrow ms-1`}
          size="md"
          style={{ zIndex: 1002 }}
          as={ButtonGroup}
        >
          <Dropdown.Toggle as={SntLink}>
            <SntFilterSettingIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {filterActionList.length > 0 ? (
              <>
                <label className="ms-2"> {language.filter_upper_key} </label>
                {filterActionList}
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default FilterTool;

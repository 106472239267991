import { useEffect, useRef, useState } from "react";

// APIs
import searchUIClient from "@/apis/searchUIClient";
import moment from "@/constants/SensolusMoment";

// Utils
import _ from "lodash";
import { DefaultTag } from "@/components/ReactBootstrap/TagMore";
import {
  bucketTypeConstant,
  supportedGroupTypeConstant,
} from "@/components/SntTableViewCard/SntTableConfigurationConstant.js";
import { useSelector } from "react-redux";
import { languageSelector } from "@redux/selectors/index.js";
import SntText from "@/components/ReactBootstrap/SntText.js";
import CheckView from "@widgets/views/CheckView";

export const useColumnDefinition = (
  queryObjectType,
  categories,
  preventCols,
  initialState = {}
) => {
  const {
    originalCols = [],
    extraCols = [],
    includeDynamicColumn = false,
  } = initialState;

  const [allColumnDefs, setAllColumnDefs] = useState(null);

  const [filterDataServer, setFilterDataServer] = useState(null);
  const serverColumnDef = useRef();
  const language = useSelector(languageSelector);
  const loginInfo = useSelector((state) => state.user);

  // Get server column definition
  useEffect(() => {
    if (!queryObjectType) return setFilterDataServer([]);

    let _categoriesParam = null;
    if (categories) {
      _categoriesParam = { categories };
    }

    searchUIClient
      .getFilterColumns(queryObjectType, _categoriesParam)
      .then(({ data }) => {
        setFilterDataServer(data);
      })
      .catch((error) => {
        setFilterDataServer([]);
      });
  }, [queryObjectType, categories]);

  useEffect(() => {
    const columns = dynamicUpdateRenderDescriptorCols();
    setAllColumnDefs(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preventCols, filterDataServer]);

  const dynamicUpdateRenderDescriptorCols = () => {
    if (!filterDataServer) return;
    let _allColumns = {};
    originalCols.forEach((obj) => {
      _allColumns[obj.key] = {
        columnKey: obj.key,
        title: obj.title,
        Cell: obj.Cell,
        style: obj.style,
        supportKibanaKey: obj.supportKibanaKey,
      };
    });
    let _map = {};
    filterDataServer.forEach((colData, index) => {
      let _key = colData.columnKey; //table description key
      if (includeDynamicColumn === false && colData.columnType === "DYNAMIC")
        return;

      let mapObj = _allColumns[_key] || {};

      mapObj.title = mapObj.title || colData.title || _key;
      mapObj.style = mapObj.style || {};

      // calculate width when no provide width style
      if (!mapObj.style.width) {
        let _size = mapObj.title.length * 7 + 15;
        let width = 100;
        if (_size > 100) {
          if (_size <= 150) {
            width = 150;
          } else if (_size <= 200) {
            width = 200;
          } else if (_size <= 250) {
            width = 250;
          } else if (_size <= 300) {
            width = 300;
          } else if (_size <= 350) {
            width = 350;
          } else if (_size <= 400) {
            width = 400;
          } else {
            width = 600;
          }
        }
        mapObj.style.width = width;
      }

      colData.settingTitle = mapObj.settingTitle;
      mapObj.columnKey = _key;
      let aggregateColumnKey = _key;

      if (colData.aggregateColumns && colData.aggregateColumns.length > 0) {
        const aggregateColumn = colData.aggregateColumns[0];
        aggregateColumnKey = aggregateColumn.alias || aggregateColumn.column;
        mapObj.supportedGroupType = colData.supportedGroupType;
        mapObj.supportedGroupUnits = colData.supportedGroupUnits;
        mapObj.alias = aggregateColumn.alias;
      }
      mapObj.aggregateColumn = aggregateColumnKey;
      mapObj.redirectFilter = colData.redirectFilter;
      mapObj.redirectColumn = colData.redirectColumn;

      mapObj.disabledGroupColumn = colData.disabledGroupColumn;

      mapObj.filterKeys = colData.filterKeys;

      // put BE column defined to tableDescriptor
      mapObj.tableDescriptor = colData;

      // if (colData.sortProperty) {
      //   mapObj.orderable = true;
      // }

      // if (colData.columnType === "DYNAMIC" && colData.sortProperty && colData.sortProperty.enums) {
      //   mapObj.Cell = ({ cell, value }) => {
      //     let full = cell.row.original;
      //     let enumVal = full[_key] || "";
      //     return <DefaultTag tagName={enumVal} />;
      //   };
      // }

      _map[_key] = mapObj;
    });
    serverColumnDef.current = _map;

    const _mapCols = JSON.parse(JSON.stringify(_map));
    const keys = Object.keys(_mapCols);
    if (keys.length > 0) {
      // options column from FE definition, need to overright BE definition
      const allRawColsByKeys = _.keyBy(originalCols, (o) => o.key);

      const _allCols = keys
        .map((key) => {
          if (
            preventCols &&
            preventCols.length &&
            preventCols.indexOf(key) !== -1
          )
            return null;
          const colObj = _mapCols[key];
          const { tableDescriptor } = colObj;

          const obj = {
            key: key,
            category: tableDescriptor.category,
            categoryId: tableDescriptor.categoryId || tableDescriptor.category,
            description: tableDescriptor.description,
            aggregateColumn: colObj.aggregateColumn,
            redirectFilter: colObj.redirectFilter,
            redirectColumn: colObj.redirectColumn,
            supportKibanaKey: colObj.supportKibanaKey,
            supportedGroupType: tableDescriptor.supportedGroupType,
            supportedGroupUnits: tableDescriptor.supportedGroupUnits,
            alias: colObj.alias,
            disabledGroupColumn: colObj.disabledGroupColumn,
            style: colObj.style || {},
            title: colObj.title,
            tableDescriptor: tableDescriptor || {},
            // sortedIndex alway -1. because the orderIndex is already removed in backend
            sortedIndex:
              tableDescriptor && Number.isInteger(tableDescriptor.orderIndex)
                ? tableDescriptor.orderIndex
                : -1,
          };

          // if column is not defined by originalCols, use dynamic render
          if (allRawColsByKeys[key]) {
            obj.title = obj.title || allRawColsByKeys[key].title;
            obj.Cell = allRawColsByKeys[key].Cell;
            obj.style = colObj.style || allRawColsByKeys[key].style;
            obj.disableSortBy = allRawColsByKeys[key].disableSortBy;
            obj.cellClass = allRawColsByKeys[key].cellClass;
          }

          //For dynamic column
          if (!obj.Cell) {
            obj.Cell = ({ cell, value }) => {
              if (typeof value === "boolean") {
                return renderCheckView(value);
              }
              if (obj.categoryId === "processDefinition") {
                return renderProcess(cell.column, cell.row, value);
              }
              return renderDynamicValue(cell.column, value) || value || "";
            };
          }

          // for DATE_BUCKET type or Cell is not defined, we use dynamic rendering
          if (
            [supportedGroupTypeConstant.DATE_BUCKET].includes(
              obj.supportedGroupType
            )
          ) {
            let origCell = obj.Cell;
            // dynamic rendering when grouped, original when not grouped
            obj.Cell = ({ cell, value }) => {
              if (cell.column.isGrouped) {
                let cellValue = value || cell.row.original[cell.column.alias];
                return (
                  renderDynamicValue(cell.column, cellValue) || cellValue || ""
                );
              } else {
                return origCell({ cell, value });
              }
            };
          }

          return obj;
        })
        .filter((col) => col);
      return [..._allCols, ...(extraCols || [])];
    }
    return [...originalCols, ...(extraCols || [])];
  };

  const renderDynamicValue = (column, value) => {
    const tableDescriptor = column.tableDescriptor;
    const isGrouped = column.isGrouped;
    const bucketType = column.bucketType;
    const supportedGroupType = tableDescriptor.supportedGroupType;
    const format = tableDescriptor.sortProperties && tableDescriptor.sortProperties.length > 0 && tableDescriptor.sortProperties[0] ? tableDescriptor.sortProperties[0].format : null;
    if (
      value &&
      tableDescriptor.sortProperties &&
      tableDescriptor.sortProperties.length > 0 &&
      !_.isEmpty(tableDescriptor.sortProperties[0].enumI18s)
    ) {
      const enumI18s = tableDescriptor.sortProperties[0].enumI18s;
      const enums = tableDescriptor.sortProperties[0].enums;
      let index = enums.indexOf(value);
      let _value =
        loginInfo &&
        enumI18s[loginInfo.language] &&
        enumI18s[loginInfo.language][index]
          ? enumI18s[loginInfo.language][index]
          : undefined;
      return _value ? <DefaultTag tagName={_value} /> : value;
    }

    if (!_.isNull(value) && !_.isUndefined(value)) {
      if (supportedGroupType === supportedGroupTypeConstant.DATE_BUCKET) {
        if (isGrouped) {
          if (bucketType === bucketTypeConstant.YEAR) {
            return moment(value).format("yyyy");
          } else if (bucketType === bucketTypeConstant.MONTH) {
            return moment(value).format("MMM yyyy");
          } else if (
            bucketType === bucketTypeConstant.DAY ||
            bucketType === bucketTypeConstant.WEEK
          ) {
            return moment(value).format("MMM DD yyyy");
          }
        }
        return moment(value).format("lll");
      }
    } else {
      return <>{language.na_key}</>;
    }

    if(format === 'URL'){
      let linkInfo = value.match(/\(([^)]+)\)/)
          ? value.match(/\(([^)]+)\)/)[1]
          : "";
      var tooltip = "";
      let link = value;
      if (linkInfo) {
        tooltip = linkInfo.match(/"([^)]+)"/)
          ? linkInfo.match(/"([^)]+)"/)[1]
          : "";
        if (tooltip) {
          link = linkInfo.substring(0, linkInfo.indexOf(" "));
        } else {
          link = linkInfo;
        }
      }
      let linkLabel = value.match(/\[([^)]+)\]/)
          ? value.match(/\[([^)]+)\]/)[1]
          : "";
      return <a target={'_blank'} rel={'noreferrer'}
          title={tooltip}
          href={link}>
          {linkLabel || link} 
          </a>;
    }

    return value || "";
  };

  const renderProcess = (column, row, value) => {
    return <SntText value={row.original[column.key + "_stateLabel"]}></SntText>;
  };

  const renderCheckView = (value) => {
    return <CheckView value={value} />;
  };

  const newFilterKeys = useRef([]);
  const sortedFilterKeys = useRef([]);

  const getNewCols = (dataOptions) => {
    let currentFilterKeys =
      dataOptions.optionsSettings && dataOptions.optionsSettings.query
        ? dataOptions.optionsSettings.query.map((q) => q.filterKey).sort()
        : [];
    newFilterKeys.current = [];
    if (
      JSON.stringify(sortedFilterKeys.current) !==
      JSON.stringify(currentFilterKeys)
    ) {
      currentFilterKeys.forEach((key) => {
        if (sortedFilterKeys.current.indexOf(key) === -1) {
          newFilterKeys.current.push(key);
        }
      });

      sortedFilterKeys.current = currentFilterKeys;
    }

    let newColumns = [];

    // add new columns from new filter keys
    newFilterKeys.current.forEach((filterKey) => {
      for (let key in serverColumnDef.current) {
        if (serverColumnDef.current[key].filterKeys.indexOf(filterKey) !== -1) {
          newColumns.push(key);
          continue;
        }
      }
    });

    return newColumns;
  };

  if (!allColumnDefs) return {};
  return { allColumnDefs, getNewCols };
};

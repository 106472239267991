import { useCallback, useEffect, useRef, useState } from "react";
import ActionHeader from "./ActionHeader/ActionHeader";
import styled from "styled-components";
import ToolbarHeader from "./Toolbar/ToolbarHeader";
import ActionToolEnum from "./Toolbar/ActionToolEnum";
import SaveFilter from "../../components/AdvancedFilters/SaveLoadFilter/SaveFilter/SaveFilter";
import LoadFilter from "../../components/AdvancedFilters/SaveLoadFilter/LoadFilter/LoadFilter";
import PagePermissionWarning from "./PagePermissionWarning/PagePermissionWarning";
import ActionTool from "./Toolbar/ActionTool";
import { ActionEnum } from "../../components/SntTableViewCard/ActionEnum";

// eslint-disable-next-line no-unused-vars
const Container = styled.div`
  display: flex;
  border: 1px solid red;
  height: 100px;
  flex-wrap: wrap;
  column-gap: 40px;
  background: lightblue;
`;
const AdminLayout = ({
  headerTitle = "",
  extraTitle = "",
  newButton = {
    disabled: false,
    isShow: false,
    link: "/",
    label: "language.add_key",
    onClick: function () {},
  },
  dateTimePickerOptions = {
    isShow: false,
    defaultOptions: {},
    hideViewModes: {},
    onChanged: function () {},
  },
  isAdvanceFilter,
  totalText,
  body,
  reload,
  pageName,
  sharedKey,
  defaultOption,
  restrictedFilters,
  toolList,
  filterList,
  switchMap,
  viewMode,
  handleActionData,
  onChangeSimpleFilterList,
  getOptionSettingsState,
  browserTabTitle,
  isShowBackHistory,
  onModeChange,
  searchPlaceholder,
  onFilterChanged,
  isHideTheSearchUI = false,
  showPermissionWarning,
  filterListRef,
  disabled = false,
  onClearAllButtonClicked,
  topContent,
  isShowHeader = true,
  includeDynamicFilter = true,
  renderEmptyData,
  numberOfAssetState,
}) => {
  const [title, setTitle] = useState(""); // title of action header
  let [showSaving, setShowSaving] = useState(false); // show the save filter dialog
  let [showLoading, setShowLoading] = useState(false); // show the load filter dialog
  const [isChanged, setChanged] = useState(false); // is the loaded filter changed
  const [dataOptions, setDataOptions] = useState(null); // the filter state (bad name)
  const [numberOfAsset, setNumberOfAsset] = useState({
    countAll: 0,
    countByFilter: 0,
    loading: true,
  }); // item counts
  const [tableSettings, setTableSettings] = useState(""); // the table state
  const [isDisabledTable, setDisabledTable] = useState(true); // is there a table ?
  const [initialDataOptions, setInitialDataOptions] = useState(null); // the default filter state

  const firstRun = useRef(true); // first load of the component ?
  const currentCols = useRef([]); // need this to detect column change by children
  const currentQueries = useRef({}); // need this to detect filter change by children
  const duplicateTitle = useRef(title); // use duplicate to reuse value before render
  const isFilterLoaded = useRef(false); // are we using a loaded filter ?
  const isApplingFilter = useRef(false); // are we first-time applying a loaded filter ?
  const duplicateChanged = useRef(isChanged); // duplicate, for what ?
  const previousOptionSettings = useRef({}); // yet another useref ?

  useEffect(() => {
    if (numberOfAssetState) {
      setNumberOfAsset(numberOfAssetState);
    }
  }, [numberOfAssetState]);

  const setInitialData = (newOptions) => {
    setInitialDataOptions(newOptions);
    if (newOptions.optionsSettings) {
      isFilterLoaded.current = newOptions.optionsSettings.isFilterLoaded
        ? newOptions.optionsSettings.isFilterLoaded
        : false;

      setChanged(newOptions.optionsSettings.isChanged);
      duplicateChanged.current = newOptions.optionsSettings.isChanged;

      setTitle(newOptions.optionsSettings.title);
      duplicateTitle.current = newOptions.optionsSettings.title;

      setDataOptions({ ...newOptions });
    }
  };

  const getSessionState = () => {
    let optionsSettings;
    let newOptions;
    if (getOptionSettingsState) {
      optionsSettings = getOptionSettingsState();
      newOptions = {
        optionsSettings: optionsSettings,
        filtersVisibleAtInit: defaultOption.filtersVisibleAtInit,
        categories: defaultOption.categories,
        deviceCategorySuggestion: defaultOption.deviceCategorySuggestion,
        queryObjectType: defaultOption.queryObjectType,
        pageName: defaultOption.pageName,
      };
    } else {
      optionsSettings = sessionStorage.getItem(pageName);
      if (optionsSettings) {
        optionsSettings = JSON.parse(optionsSettings);
      } else {
        optionsSettings = {};
      }

      if (sharedKey) {
        var lastState = sessionStorage.getItem(sharedKey);
        if (lastState) {
          var obj = JSON.parse(lastState);

          optionsSettings.query = obj.query || [];
          optionsSettings.title = obj.title;
          optionsSettings.isChanged = obj.isChanged;
          optionsSettings.isFilterLoaded = obj.isFilterLoaded;
        }
      }

      newOptions = {
        optionsSettings: optionsSettings.query
          ? optionsSettings
          : defaultOption.optionsSettings || {},
        filtersVisibleAtInit: defaultOption.filtersVisibleAtInit,
        queryObjectType: defaultOption.queryObjectType,
        categories: defaultOption.categories,
        deviceCategorySuggestion: defaultOption.deviceCategorySuggestion,
        pageName: defaultOption.pageName,
      };
    }

    isApplingFilter.current =
      optionsSettings && optionsSettings.isFilterLoaded ? true : false;
    setInitialData(newOptions);
  };

  const setSessionState = (data) => {
    let oldOptionsSettings = sessionStorage.getItem(pageName);
    if (oldOptionsSettings) {
      oldOptionsSettings = JSON.parse(oldOptionsSettings);
    }
    // keep old states
    if (oldOptionsSettings) {
      data.tableSettings =
        data.tableSettings || oldOptionsSettings.tableSettings;
      data.currentBounds =
        data.currentBounds || oldOptionsSettings.currentBounds;
    }

    setTableSettings(data.tableSettings);
    let _data = {
      searchQueryType: data.searchQueryType,
      query: data.query || [],
      page: data.page,
      start: data.start,
      limit: data.limit,
      orderData: data.orderData,
      tableSettings: data.tableSettings,
      title: duplicateTitle.current,
      isChanged: duplicateChanged.current,
      isFilterLoaded: isFilterLoaded.current,
      currentBounds: data.currentBounds,
    };

    sessionStorage.setItem(pageName, JSON.stringify(_data));
    previousOptionSettings.current = _data;
    if (sharedKey) {
      sessionStorage.setItem(
        sharedKey,
        JSON.stringify({
          query: dataOptions.optionsSettings.query,
          title: duplicateTitle.current,
          isChanged: duplicateChanged.current,
          isFilterLoaded: isFilterLoaded.current,
        })
      );
    }
  };

  const onChangeChildren = (_data, numberAsset) => {
    if (_data) {
      if (isFilterLoaded.current) {
        const _tableSettings = _data.tableSettings || {
          columnVisibleInOrder: null,
        };
        const _query = _data.query || null;
        if (
          !isApplingFilter.current &&
          (JSON.stringify(currentCols.current) !==
            JSON.stringify(_tableSettings.columnVisibleInOrder) ||
            JSON.stringify(currentQueries.current) !== JSON.stringify(_query))
        ) {
          currentCols.current = JSON.parse(
            JSON.stringify(_tableSettings.columnVisibleInOrder)
          );
          currentQueries.current = JSON.parse(JSON.stringify(_query));
          setChanged(true);
          duplicateChanged.current = true;
        } else {
          isApplingFilter.current = false;
          setChanged(false);
          duplicateChanged.current = false;
        }
      }
      setSessionState(_data);
    }
    if (numberAsset) {
      setNumberOfAsset({ ...numberAsset, loading: false });
    } else if (numberOfAsset.loading) {
      setNumberOfAsset({ ...numberOfAsset, loading: false });
    }
  };

  const onChangeFilter = (_data, action) => {
    // set new data options
    let _dataOptions = dataOptions || defaultOption;
    let newOptions = _dataOptions.optionsSettings;
    newOptions.query = _data.query;
    newOptions.searchQueryType = _data.searchQueryType;
    if (isFilterLoaded.current) {
      newOptions.tableSettings =
        initialDataOptions.optionsSettings.tableSettings;
    }
    _dataOptions.optionsSettings = newOptions;

    setDataOptions({
      ..._dataOptions,
      optionsSettings: newOptions,
      action: isApplingFilter.current
        ? { type: ActionEnum.LOAD_FILTER }
        : action,
    });

    setDisabledTable(false);
    onFilterChanged && onFilterChanged(_data.query);
  };

  const onClearFilters = () => {
    setChanged(false);
    duplicateChanged.current = false;
    isFilterLoaded.current = false;

    let newOption = {
      ...defaultOption,
      optionsSettings: {
        ...defaultOption.optionsSettings,
        query: [],
        title: headerTitle,
        isChanged: false,
        isFilterLoaded: false,
      },
    };
    setInitialData(newOption);
  };

  const onApplyFilter = (filter) => {
    let _options = {};
    _options.title = filter.name;
    _options.searchQueryType = filter.queryType;
    _options.tableSettings = filter.tableSettings;
    _options.query = JSON.parse(filter.appliedFilterSettings);
    _options.isChanged = false;
    _options.isFilterLoaded = true;

    isApplingFilter.current = true;
    isFilterLoaded.current = true;
    duplicateChanged.current = false;
    duplicateTitle.current = filter.name;

    setChanged(false);
    setTitle(filter.name);

    setInitialData({ ...dataOptions, optionsSettings: _options });
    setShowSaving(false);
    setShowLoading(false);
  };

  const onSaveFilter = (title) => {
    setTitle(title);
    setChanged(false);
    duplicateTitle.current = title;
    duplicateChanged.current = false;

    // store at session storage
    previousOptionSettings.current.isChanged = false;
    previousOptionSettings.current.title = title;
    sessionStorage.setItem(
      pageName,
      JSON.stringify(previousOptionSettings.current)
    );
    setShowSaving(false);
  };

  const handleTools = useCallback(
    (id) => {
      switch (id) {
        case ActionToolEnum.save_filter:
          setShowSaving(true);
          break;
        case ActionToolEnum.load_filter:
          setShowLoading(true);
          break;
        case ActionToolEnum.reset_filter:
          onClearFilters();
          break;
        default:
          handleActionData(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleActionData]
  );

  useEffect(() => {
    if (isAdvanceFilter) {
      getSessionState();
    } else {
      setInitialDataOptions(defaultOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAdvanceFilter) {
      if (!firstRun.current && dataOptions) {
        setInitialData({ ...dataOptions });
      }
      firstRun.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const onClose = () => {
    setShowLoading(false);
    setShowSaving(false);
  };

  return (
    <>
      {dataOptions ? (
        <>
          <SaveFilter
            isShow={showSaving}
            onSave={onSaveFilter}
            onClose={onClose}
            data={{
              title: title,
              tableSettings: tableSettings,
              queryObjectType: dataOptions.queryObjectType,
              appliedFilterSettings: dataOptions.optionsSettings.query,
            }}
          />
          <LoadFilter
            isShow={showLoading}
            onLoadSearchQuery={onApplyFilter}
            onClose={onClose}
            queryObjectType={dataOptions.queryObjectType || "SIGFOX_DEVICE"}
          />
        </>
      ) : null}

      {showPermissionWarning ? (
        <PagePermissionWarning pageName={pageName} />
      ) : (
        ""
      )}

      {isShowHeader && (browserTabTitle || title || headerTitle) && (
        <ActionHeader
          title={
            <>
              {(title || headerTitle) + (isChanged ? " *" : "")} {extraTitle}
            </>
          }
          tabTitle={browserTabTitle || title || headerTitle}
          isShowBackHistory={isShowBackHistory}
          dateTimePickerOptions={dateTimePickerOptions}
          toolbar={
            <div
              className={`w-100 ${
                numberOfAsset.countAll === 0 &&
                !numberOfAsset.loading &&
                renderEmptyData
                  ? "d-none"
                  : ""
              }`}
            >
              <ActionTool
                list={toolList}
                onClick={handleTools}
                group={
                  switchMap && { viewMode: viewMode, onChange: onModeChange }
                }
                heroAction={newButton}
              />
            </div>
          }
        />
      )}

      <div
        className={`${
          numberOfAsset.countAll === 0 &&
          !numberOfAsset.loading &&
          renderEmptyData
            ? ""
            : "d-none"
        }`}
      >
        {renderEmptyData && renderEmptyData()}
      </div>

      <div
        className={`${
          numberOfAsset.countAll === 0 &&
          !numberOfAsset.loading &&
          renderEmptyData
            ? "d-none"
            : ""
        }`}
      >
        {topContent && topContent()}
        {initialDataOptions && (
          <ToolbarHeader
            restrictedFilters={restrictedFilters}
            onChange={onChangeFilter}
            dataOptions={initialDataOptions}
            numberAsset={numberOfAsset}
            pageName={pageName}
            filterList={filterList}
            handleTool={handleTools}
            isAdvanceFilter={isAdvanceFilter}
            totalText={totalText}
            onChangeSimpleFilterList={onChangeSimpleFilterList}
            searchPlaceholder={searchPlaceholder}
            isHideTheUI={isHideTheSearchUI}
            filterListRef={filterListRef}
            disabled={disabled}
            onClearAllButtonClicked={onClearAllButtonClicked}
            includeDynamicFilter={includeDynamicFilter}
          >
            {() =>
              body({
                isDisabled: isDisabledTable,
                dataOptions: dataOptions,
                onChangeChildren: onChangeChildren,
              })
            }
          </ToolbarHeader>
        )}
      </div>
      {/*</Container>*/}
    </>
  );
};
export default AdminLayout;
